import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import { mediaQueries, color } from '../variables';
import TeamContainer from './TeamContainer';
import * as S from '../shared/components/styles';

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 5rem;
  display: block;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const CardsWrapper = styled(Row)`
  padding: 0;
  display: flex;
  justify-content: center;
  ${mediaQueries.xl} {
    padding: 0 5rem;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const SectionWrapper = styled(Col)`
  margin: 0;
  justify-content: center;
  width: 100%;
  background-color: ${color.white};
  background-size: cover;
`;

const MeetOurTeamSection = () => (
  <SectionWrapper>
    <ContentRow>
      <S.SectionHeading>Leadership</S.SectionHeading>
    </ContentRow>
    <CardsWrapper type="flex">
      <TeamContainer />
    </CardsWrapper>
  </SectionWrapper>
);

export default MeetOurTeamSection;
