import React from 'react';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';

import circleIcon from '../../images/oval-about-us2.svg';
import { mediaQueries } from '../variables';
import * as S from '../shared/components/styles';

const SectionWrapper = styled(Col)`
  padding: 15rem 9rem 0;
  &:last-of-type {
    padding-bottom: 4rem;
  }
  ${mediaQueries.xl} {
    padding: 12rem 5rem 0;
  }
  ${mediaQueries.lg} {
    padding: 10rem 2rem 0;
  }
  ${mediaQueries.sm} {
    padding: 7rem 2rem 0;
  }
  background-color: white;
  background-size: cover;
`;

const ContentRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
`;

const SectionIcon = styled.img(({ rightIcon }) => ({
  bottom: rightIcon ? 0 : '3rem',
  zIndex: rightIcon ? 1 : 5,
  right: rightIcon && 0,
  top: rightIcon && '20rem',
  display: 'block',
  width: rightIcon ? '24rem' : '46rem',
  position: rightIcon ? 'absolute' : 'relative',
  margin: '0 auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  [mediaQueries.xxl]: {
    bottom: 0,
    width: rightIcon ? '18rem' : '35rem',
  },
  [mediaQueries.xl]: {
    width: rightIcon ? '18rem' : '28rem',
  },
  [mediaQueries.lg]: {
    display: rightIcon ? 'none' : 'block',
    width: '31rem',
  },
  [mediaQueries.md]: {
    width: '30rem',
  },
  [mediaQueries.sm]: {
    width: '20rem',
  },
  [mediaQueries.xs]: {
    width: '16rem',
  },
}));

const AboutUsSection = () => (
  <>
    <SectionWrapper>
      <ContentRow>
        <S.SectionWelcomeHeading>Our Team</S.SectionWelcomeHeading>
        <S.SectionDescription
          semiTranspatentOpacity
          css={{
            [mediaQueries.md]: { margin: '0 auto' },
            [mediaQueries.sm]: { paddingBottom: '2rem' },
          }}
        >
          We are a team of entrepreneurs, clinicians, academics, engineers, writers, athletes,
          yoga instructors, and more. We all have experienced feelings of stress, anxiety, or
          loneliness at some point in our lives without the proper recourse to manage them. We’re
          fed up with the lack of resources for the Modern employee, and we’re here to do
          something about it.
        </S.SectionDescription>
      </ContentRow>
    </SectionWrapper>
    <SectionIcon rightIcon src={circleIcon} />
  </>
);

export default AboutUsSection;
