import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import creativityIcon from '../../images/icon-creativity.svg';
import determinationIcon from '../../images/icon-detrmination.svg';
import transparencyIcon from '../../images/icon-transparency.svg';
import inspirationIcon from '../../images/icon-inspiration.svg';
import positivityIcon from '../../images/icon-positivity.svg';
import empathyIcon from '../../images/icon-empathy.svg';
import cardsOvalIcon from '../../images/oval-about-us-1.svg';
import { SectionDescription } from '../shared/components/styles';
import { mediaQueries, color } from '../variables';
import Card from '../shared/components/Card';
import * as S from '../shared/components/styles';

const cards = [
  {
    icon: creativityIcon,
    title: 'Creativity',
    description: 'We believe imagination is the key to innovation.',
  },
  {
    icon: determinationIcon,
    title: 'Determination',
    description: 'We are here to make a positive impact, and nothing can stop us.',
  },
  {
    icon: transparencyIcon,
    title: 'Transparency',
    description: 'We share our ideas, actions, mistakes, and wins.',
  },
  {
    icon: inspirationIcon,
    title: 'Inspiration',
    description: 'We are inspired by big ideas and passions.',
  },
  {
    icon: positivityIcon,
    title: 'Positivity',
    description: 'We smile even when the going gets tough.',
  },
  {
    icon: empathyIcon,
    title: 'Empathy',
    description: 'We care because we understand.',
  },
];

const SectionWrapper = styled(Col)`
  padding: 7rem 0;
  width: 100%;
  &:last-of-type {
    padding-bottom: 4rem;
  }
  ${mediaQueries.xl} {
    padding: 5rem 5rem 0;
  }
  ${mediaQueries.lg} {
    padding: 5rem 1rem 3rem;
  }
  background-color: ${color.white};
  background-size: 'cover';
`;

const ContentRow = styled(Row)`
  width: 100%;
  display: block;
  text-align: center;
  padding: 0 5rem;
  ${mediaQueries.xl} {
    padding: 0;
  }
  ${mediaQueries.sm} {
    margin-bottom: 2rem;
  }
`;

const ContentWrapper = styled(Col)`
  text-align: left;
  display: block;
  ${mediaQueries.xl} {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  ${mediaQueries.sm} {
    margin: 0;
    padding: 0 1rem;
  }
`;

const CardsWrapper = styled(Row)`
  padding: 0 3rem;
  display: flex;
  justify-content: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
  ${mediaQueries.lg} {
    padding-top: 5rem;
  }
`;

const SectionTextWrapper = styled.div`
  padding-top: 20rem;
  ${mediaQueries.xl} {
    padding-top: 0;
  }
`;

export const SectionIcon = styled.img(() => ({
  top: '2rem',
  display: 'block',
  position: 'absolute',
  width: '43rem',
  height: '43rem',
  backgroundRepeat: 'no-repeat',
  [mediaQueries.xxl]: {
    width: '35rem',
    height: '35rem',
  },
  [mediaQueries.xl]: {
    width: '43rem',
    height: '43rem',
  },
  [mediaQueries.md]: {
    top: '10rem',
    width: '38rem',
    height: '38rem',
  },
  [mediaQueries.sm]: {
    display: 'none',
  },
}));

const OurValuesSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col lg={24} xl={14} xxl={12}>
          <CardsWrapper type="flex">
            <SectionIcon src={cardsOvalIcon} />
            {cards.map(card => (
              <Card
                square={1}
                noBorder
                smallIcon
                smallContent
                allignContentLeft
                key={card.icon}
                icon={card.icon}
                title={card.title}
                description={card.description}
              />
            ))}
          </CardsWrapper>
        </Col>
        <Col lg={24} xl={10} xxl={{ span: 8, offset: 2 }}>
          <SectionTextWrapper>
            <S.SectionHeading>Our Values</S.SectionHeading>
            <SectionDescription css={{ opacity: 0.8, [mediaQueries.xs]: { textAlign: 'left' } }}>
              We’re redesigning the mental health landscape to provide comprehensive support for the
              Modern worker.
            </SectionDescription>
          </SectionTextWrapper>
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default OurValuesSection;
