import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import FooterWrapper from '../components/index/FooterWrapper';
import AboutUsSection from '../components/about/AboutUsSection';
import OurValuesSection from '../components/about/OurValuesSection';
import MeetOurTeamSection from '../components/about/MeetOurTeamSection';
import JoinTeamSection from '../components/providers/JoinTeamSection';

const AboutUsPage = () => (
  <Layout>
    <Navigation />
    <AboutUsSection />
    <OurValuesSection />
    <MeetOurTeamSection />
    <FooterWrapper children={<JoinTeamSection email={'mailto:careers@joinmodenrhealth.com'}/>} />
  </Layout>
);

export default AboutUsPage;
