import React from 'react';
import styled from '@emotion/styled';
import { Col, Button } from 'antd';

import alysonPhoto from '../../images/team/af-image.jpg';
import myraPhoto from '../../images/team/myra.jpg';
import erikPhoto from '../../images/team/erik.jpg';
import { mediaQueries, color } from '../variables';

const team = [
  {
    photo: alysonPhoto,
    name: 'Alyson Friedensohn',
    role: 'Co-Founder & CEO',
    linkedIn: 'https://www.linkedin.com/in/alyson-friedensohn-58317b27/',
  },
  {
    photo: myraPhoto,
    name: 'Myra Altman, PhD',
    role: 'Clinical Care',
    linkedIn: 'https://www.linkedin.com/in/myraaltman/',
  },
  {
    photo: erikPhoto,
    name: 'Erik Peterson',
    role: 'Engineering',
    linkedIn: 'https://www.linkedin.com/in/erik-peterson-5071a7120/',
  },
];

const CardWrapper = styled(Col)(() => ({
  margin: '1rem',
  minWidth: '240px',
  minHeigh: '240px',
  [mediaQueries.xl]: {
    margin: '2rem',
  },
  [mediaQueries.sm]: {
    display: 'block',
    justifyContent: 'center',
  },
}));

const CardContainer = styled.div(() => ({
  display: 'block',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  backgroundColor: color.white,
  cursor: 'pointer',
}));

const CardPhoto = styled.img(({ iconUrl }) => ({
  margin: '0 auto',
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: '18rem',
  height: '18rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  [mediaQueries.lg]: {
    width: '17rem',
    height: '17rem',
  },
}));

const CardTitle = styled.h3(() => ({
  width: '100%',
  margin: '0',
  opacity: 0.84,
  color: color.midnight,
  paddingTop: '1rem',
  fontSize: '22px',
}));

const CardContent = styled.p(() => ({
  margin: '0rem 2rem',
  color: color.bermudaGrey,
  fontSize: '18px',
  [mediaQueries.sm]: {
    fontSize: '16px',
    padding: '0',
    margin: '0',
  },
}));

const StyledButton = styled(Button)`
  width: 11.5rem;
  height: 2.5rem;
  margin-bottom: 3rem;
  color: ${color.royalBlue};
  border-radius: 0.5rem;
  border-color: ${color.royalBlue};
`;

const ButtonWrapper = styled.div`
  display: none;
  ${mediaQueries.sm} {
    display: flex;
  }
`;

const TeamContainer = () => (
  <>
    {team.map(person => (
      <CardWrapper key={person.name} sm={24} md={6} lg={6} xl={5}>
        <CardContainer>
          <a href={person.linkedIn}>
            <CardPhoto src={person.photo} />
            <CardTitle smallContent>{person.name}</CardTitle>
            <CardContent smallContent>{person.role}</CardContent>
          </a>
        </CardContainer>
      </CardWrapper>
    ))}
  </>
);

export default TeamContainer;
